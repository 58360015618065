<template>
  <div>
    <v-card
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Créer une formation
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiSchool }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-form>
        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                v-model="newFormation.name"
                label="Nom de la formation"
                outlined
                dense
                placeholder="Nom de la formation"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newFormation.edofLink"
                label="Lien EDOF de la formation"
                placeholder="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/89071917200029_2461/89071917200029_1234"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newFormation.duration"
                label="Durée de la formation"
                outlined
                suffix="heures"
                dense
                type="number"
                placeholder="Durée de la formation"
                :hint="`${Number(newFormation.duration)} heures dont ${Number(newFormation.visio)} heures de visio`"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newFormation.visio"
                type="number"
                :max="newFormation.duration"
                label="Durée du visio"
                outlined
                dense
                suffix="heure(s)"
                placeholder="Durée visio"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="newFormation.certification"
                clearable
                :items="certifications"
                item-text="name"
                item-value="_id"
                label="Certification"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-select
                v-model="newFormation.tuteur"
                :items="tuteurs"
                clearable
                item-text="nom"
                item-value="id"
                label="Tuteur"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newFormation.price"
                label="Prix de la formation"
                type="number"
                outlined
                dense
                suffix="€"
                placeholder="Prix de la formation"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <div
            v-for="(variation, index) in newFormation.variations"
            :key="index"
          >
            <v-divider></v-divider>
            <div class="d-flex justify-space-around">
              <v-spacer></v-spacer>
              <p class="text-h5 text--primary text-md-center mt-3">
                Variation {{ index +1 }}
              </p>
              <v-spacer></v-spacer>
              <v-btn
                icon
                color="error"
                @click="removeVariation(index)"
              >
                <v-icon>{{ icons.mdiTrashCan }}</v-icon>
              </v-btn>
            </div>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newFormation.variations[index].price"
                  :label="`Prix de la variation ${index +1}`"
                  type="number"
                  outlined
                  dense
                  suffix="€"
                  placeholder="1200"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="newFormation.variations[index].edofLink"
                  :label="`Lien EDOF de la variation ${index +1}`"
                  placeholder="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/89071917200029_2461/89071917200029_1234"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newFormation.variations[index].duration"
                  :label="`Durée de la variation ${index+1}`"
                  outlined
                  suffix="heures"
                  dense
                  type="number"
                  placeholder="5"
                  :hint="`${Number(newFormation.variations[index].duration)} heures dont ${Number(newFormation.variations[index].visio)} heures de visio`"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="newFormation.variations[index].visio"
                  type="number"
                  :max="newFormation.variations[index].duration"
                  :label="`Durée du visio de la variation ${index+1}`"
                  outlined
                  dense
                  suffix="heure(s)"
                  placeholder="4"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text>
          <v-btn
            color="primary"
            outlined
            block
            @click="addVariation"
          >
            Ajouter une variation
          </v-btn>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            type="reset"
            outlined
            class="mx-2"
            @click="reset"
          >
            Réinitialiser
          </v-btn>
          <v-btn
            color="primary"
            @click="createFormation"
          >
            Créer
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>

import { mdiSchool, mdiTrashCan } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Créer une formation',
    titleTemplate: '%s - Jimbo',
  },
  components: {
  },
  data() {
    return {
      icons: {
        mdiSchool,
        mdiTrashCan,
      },
      newFormation: {
        certification: null,
        company: '',
        name: '',
        duration: '',
        price: 0,
        cost: 0,
        visio: null,
        edofLink: '',
        tuteur: [0],
        variations: [],
      },
      tuteurs: this.$store.getters.getTutors,
      certifications: [],
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchCertifs()])
        .then(values => {
          [this.certifications] = values
        })
        .catch(err => console.log(err))
        .finally(
          this.loading = false,
        )
    },
    addVariation() {
      this.newFormation.variations.push({
        duration: 0,
        price: 0,
        visio: 0,
        edofLink: '',
      })
    },
    removeVariation(index) {
      this.newFormation.variations.splice(index, 1)
    },
    async fetchCertifs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/certification/all/${this.$store.state.company._id}`)

      return response.data
    },
    reset() {
      this.newFormation.name = ''
      this.newFormation.duration = 0
      this.newFormation.visio = 0
      this.newFormation.price = 0
      this.newFormation.certification = null
      this.newFormation.edofLink = ''
      this.newFormation.tuteur = null
      this.newFormation.variations = []
    },
    createFormation() {
      this.newFormation.company = this.$store.state.company._id
      this.$http.post(`${process.env.VUE_APP_API_URL}/course/new`, this.newFormation)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Formation crée avec succès',
            value: true,
          })
          this.reset()
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création de la formation',
            value: true,
          })
        })
    },
  },
}
</script>

<style>

</style>
